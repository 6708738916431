// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "23.2.0",
  "buildNumber": "16938",
  "gitSha": "e2b2936f3b573008381a3702139ebcb4383dc0b1",
  "fullVersion": "23.2.0-16938",
  "formattedVersion": "23.2.0 (16938-e2b2936f3b573008381a3702139ebcb4383dc0b1)",
  "copyrightYear": "2023"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/23.2.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/23.2.0/${stripLeadingPrefix(suffix, '#')}`
}
    
